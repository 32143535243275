import profile from './profile.jpg';
import './Banner.css';

const Banner = () => {
  return (
    <div className="banner">
      <div>
        <h1 className="presentation">
          Hola, soy <span className="name">Efren</span>. Programador, científico de datos y pianista
          aficionado.
        </h1>
      </div>
      <div className="description-wrapper">
        <div className="description">
          <p>
            Tengo más de 7 años de experiencia trabajando como programador, 2 años de experiencia
            trabajando como científico de datos y dos años trabajando como instructor de
            programación.
          </p>
          <p>
            He trabajando como freelancer en varios proyectos para la UNAM, para la agencia de
            publicidad DigitalLab y para la organización Creatures United donde estamos trabajando
            en una de las bases de datos de especies en peligro de extinción más grande del mundo.
          </p>
          <p>
            Como empleado he trabajado en proyectos para la CONABIO, Cinepolis, HP, Fender, entre
            algunos otros.
          </p>
        </div>
        <div className="profile-img-wrapper">
          <img src={profile} alt="Foto de Efren Cabrera" />
        </div>
      </div>
    </div>
  );
};

export default Banner;
