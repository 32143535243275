import Banner from './ components/Banner';
import TopBar from './ components/TopBar';
import './App.css';

function App() {
  return (
    <div className="App">
      <TopBar />
      <Banner />
      <div className="contact">
        Contacto:{' '}
        <a className="email" href="mailto:efren@cabrera.dev">
          efren@cabrera.dev
        </a>
      </div>
    </div>
  );
}

export default App;
